
import { CATEGORY_OUT_STANDING, INDEX_SET_LOADING } from '../../store/store.const'
import { removeVietnameseTones, textToSlug } from '../../utils/utils'

export default {
  name: 'CategoryHot',
  data() {
    return {
      listCategory: []
    }
  },
  async fetch() {
    await this.fetchDataCategory()
  },
  methods: {
    changeToLink(link) {
      this.$router.push(link)
    },
    async fetchDataCategory() {
      try {
        await this.$store.commit(INDEX_SET_LOADING, true)
        const category = await this.$store.dispatch(CATEGORY_OUT_STANDING)
        const list = []
        category.data.forEach(cat => {
          list.push({
            id: cat.id,
            title: cat.name,
            link: '/category/' + textToSlug(removeVietnameseTones(cat.name)) + '.' + cat.id,
            image: cat.image_url,
            image_thumb_url: cat.image_thumb_url
          })
        })
        this.listCategory = list
      } catch (e) {
        console.log(e)
      }
      await this.$store.commit(INDEX_SET_LOADING, false)
    }
  }
}
