
import { mapState } from 'vuex'

export default {
  name: 'TypicalProject',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      setting: state => state.setting
    })
  },
  methods: {
    checkHaseVideo() {
      const urlVd = this.setting.intro_video?.replace('https://www.youtube.com/embed/', '')
      if (urlVd) {
        return true
      }
      return false
    }
  }
}
