
import SlideShow from '../components/home/SildeShow'
// import StoreHot from '../components/home/StoreHot'
import CategoryHot from '../components/home/CategoryHot'
// import ProductDaily from '../components/home/ProductDaily'
import SellingProduct from '../components/home/SellingProduct'
import DealHot from '../components/home/DealHot'
import TypicalProject from '../components/home/TypicalProject'
import IntroVideo from '../components/home/IntroVideo'
import LastSeen from '../components/home/LastSeen'

export default {
  name: 'IndexPage',
  // components: { SlideShow, StoreHot, CategoryHot, SellingProduct, DealHot, TypicalProject, IntroVideo, LastSeen },
  components: { SlideShow, CategoryHot, SellingProduct, DealHot, TypicalProject, IntroVideo, LastSeen },
  data() {
    return {
      windowWidth: 0
    }
  },
  mounted() {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    document.title = this.$t('home.title')
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  }
}
