
import { DEAL_HOT_LIST } from '../../store/store.const'
import SingleProductElement from '../product/single'

export default {
  name: 'DealHot',
  components: {
    SingleProductElement
  },
  data() {
    return {
      products: [],
      fullscreenLoading: false
    }
  },
  async fetch() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const products = await this.$store.dispatch(DEAL_HOT_LIST)
        this.products = products.data
      } catch (e) {
        console.log(e)
      }
    }
  }
}
