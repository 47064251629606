
import { TYPICAL_BLOGS_LIST } from '../../store/store.const'
import { removeVietnameseTones, textToSlug } from '../../utils/utils'

export default {
  name: 'TypicalProject',
  data() {
    return {
      blogs: [],
      fullscreenLoading: false
    }
  },
  async fetch() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const blogs = await this.$store.dispatch(TYPICAL_BLOGS_LIST)
        this.blogs = blogs.data
      } catch (e) {
        console.log(e)
      }
    },
    slugData(name) {
      return textToSlug(removeVietnameseTones(name))
    }
  }
}
