
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { GET_BANNER } from '@/store/store.const'

export default {
  name: 'SlideShow',
  components: { VueSlickCarousel },
  // eslint-disable-next-line vue/require-prop-types
  props: ['windowWidth'],
  data() {
    return {
      slideSetting: {
        'dots': true,
        'autoplaySpeed': 6500,
        'dotsClass': 'slick-dots custom-dot-class',
        'edgeFriction': 0.35,
        'infinite': true,
        'speed': 500,
        'slidesToShow': 1,
        'slidesToScroll': 1
      },
      listSlide: [],
      topBanner: {},
      bottomBanner: {},
      carouselKey: 0
    }
  },
  async fetch() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const banner = await this.$store.dispatch(GET_BANNER)
        if (banner.status_code === 200) {
          if (banner.data.main.length > 0) {
            this.listSlide = banner.data.main
          } else {
            this.listSlide = [
              {
                title: '',
                link: '',
                url: '/assets/images/home/banner web-00-min.gif'
              },
              {
                title: '',
                link: '',
                url: '/assets/images/home/banner web-06-min.png'
              },
              {
                title: '',
                link: '',
                url: '/assets/images/home/banner web-05-min.png'
              },
              {
                title: '',
                link: '',
                url: '/assets/images/home/banner web-04-min.png'
              },
              {
                title: '',
                link: '',
                url: '/assets/images/home/banner web-03-min.png'
              },
              {
                title: '',
                link: '',
                url: '/assets/images/home/banner web-01-min.png'
              }
            ]
          }
          if (banner.data.top) {
            this.topBanner = banner.data.top
          } else {
            this.topBanner = {
              title: '',
              link: '',
              url: '/assets/images/home/banner web-07-min.png'
            }
          }
          if (banner.data.bottom) {
            this.bottomBanner = banner.data.bottom
          } else {
            this.bottomBanner = {
              title: '',
              link: '',
              url: '/assets/images/home/banner web-02-min.png'
            }
          }
          this.carouselKey++
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
