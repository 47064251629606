
import { formatPrice, removeVietnameseTones, textToSlug } from '../../utils/utils'
export default {
  name: 'SimpleProductElement',
  // eslint-disable-next-line vue/require-prop-types
  props: ['product'],
  methods: {
    formatPrice(val) {
      return formatPrice(val)
    },
    slugData(name) {
      return textToSlug(removeVietnameseTones(name))
    }
  }
}
