
import { formatPrice, removeVietnameseTones, textToSlug } from '../../utils/utils'
export default {
  name: 'SingleProductElement',
  // eslint-disable-next-line vue/require-prop-types
  props: ['product', 'like', 'isProductSite'],
  methods: {
    formatPrice(val) {
      return formatPrice(val)
    },
    showSold(val) {
      let sold = Number(val)
      if (sold > 1000) {
        sold = (sold / 1000).toFixed(1) + 'k'
        sold = sold.replace('.0', '')
        sold = sold.replace('.', ',')
      }
      return sold
    },
    slugData(name) {
      return textToSlug(removeVietnameseTones(name))
    }
  }
}
