
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import SimpleProductElement from '../product/simple'
import { PRODUCT_LAST_SEEN } from '../../store/store.const'

export default {
  name: 'LastSeen',
  components: {
    SimpleProductElement, VueSlickCarousel
  },
  data() {
    return {
      products: [],
      slideSetting: {
        'dots': false,
        'infinite': false,
        'initialSlide': 0,
        'speed': 500,
        'slidesToShow': 6,
        'slidesToScroll': 1,
        'swipeToSlide': false
      },
      slideSettingTab: {
        'dots': false,
        'infinite': false,
        'initialSlide': 0,
        'speed': 500,
        'slidesToShow': 3,
        'slidesToScroll': 1,
        'swipeToSlide': false
      },
      fullscreenLoading: false,
      viewProduct: 4
    }
  },
  async fetch() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const products = await this.$store.dispatch(PRODUCT_LAST_SEEN)
        this.products = products.data
        this.slideSetting.touchMove = this.products.length > 6
        this.slideSettingTab.touchMove = this.products.length > 6
      } catch (e) {
        console.log(e)
      }
    }
  }
}
